div.container{
    border: 5px dotted gray;
}
.row.first .col-md-3{
    border-right: 2px solid gray;
    padding-left: 6px;
    border-bottom: 2px solid gray;
}
.row.first .col-md-6{
    border-right: 2px solid gray;
    border-bottom: 2px solid gray;
    padding: 0;
}
.bordertop{
    border-top: 2px solid gray;
}
.rightborder{
    border-right: 2px solid gray;
}
.bottomborder{
    border-bottom: 2px solid gray;
}
.boxshadow{
    box-shadow: 3px 2px 3px 1px gray;
    width: 100%;
    height: 100%;
}
.col-md-5.subjectsrows .row{
    margin-top: 5px;
}
.mt{
    margin-top: 5px;    
}