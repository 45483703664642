@import url(https://fonts.googleapis.com/css?family=Montserrat);
@import url(https://fonts.googleapis.com/css?family=Open+Sans:400,700);

.under h1 {
  margin: 0;
  font-family: 'Montserrat', sans-serif;
  font-size: 4em;
  color: #333;
  -webkit-text-shadow: 0 2px 1px rgba(0, 0, 0, 0.6), 0 0 2px rgba(0, 0, 0, 0.7);
  -moz-text-shadow: 0 2px 1px rgba(0, 0, 0, 0.6), 0 0 2px rgba(0, 0, 0, 0.7);
  text-shadow: 0 2px 1px rgba(0, 0, 0, 0.6), 0 0 2px rgba(0, 0, 0, 0.7);
  word-spacing: 16px;
  line-height: 1;
  /* margin-bottom: 40px; */
}

.under p {
  font-family: 'Open Sans', sans-serif;
  font-size: 1.4em;
  font-weight: bold;
  color: #222;
  text-shadow: 0 0 40px #FFFFFF, 0 0 30px #FFFFFF, 0 0 20px #FFFFFF;
}

.under .container {
  position: absolute;
  top: 0;
  bottom: 0;
  width: 100%;
  /* background: url(''); */
  background-size: cover;
}

.under .wrapper {
  width: 100%;
  min-height: 100%;
  height: auto;
  display: table;
}

.under .content {
  display: table-cell;
  vertical-align: middle;
}

.under .item {
  width: auto;
  height: auto;
  margin: 0 auto;
  text-align: center;
  padding: 8px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex-wrap: wrap;
  align-items: center;
  min-height: 80vh;
}

@media only screen and (min-width: 800px) {
  .under h1 {
    font-size: 6em;
  }
  .under p {
    font-size: 1.6em;
  }
}

@media only screen and (max-width: 320px) {
  .under h1 {
    font-size: 2em;
  }
  .under p {
    font-size: 1.2em;
  }
}